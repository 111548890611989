html {
  font-size: 12px;
  text-align: left;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.multistatecountry > div.p-checkbox-box.p-highlight, .multistatecountry>div.p-checkbox-box.p-highlight:hover, .multistatecountry>div.p-checkbox-box.p-highlight:focus, .multistatecountry>div.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: transparent!important;
  background: transparent!important;
  box-shadow: none!important;
}

.legals button.p-dialog-header-icon:focus {
  box-shadow: 0 0 0 0.2rem rgb(160, 200, 88, 0.2)!important;
}

.legals button.p-dialog-header-icon:hover {
  background: rgb(160, 200, 88, 0.2)!important;
}

.ticketList > .filterColumn {
  width: 20%;
}

.ticketForm .p-autocomplete button {
  background-color: transparent;
  border-color: transparent;
  color: var(--primary-color);
}

div.tickethistoryentry {
  max-width: 100%;
  box-shadow: none;
  text-align: left;
}

.tickethistoryentry img {
  max-width: 100% !important;
  height: auto !important;
}

.p-timeline .p-speeddial-button.p-button.p-button-icon-only {
  width: 35px;
  height: 35px;
  border: none;
  border-color: #fff;
}

.image-modal>div.p-dialog-content,
.image-modal>div.p-dialog-header {
  background-color: transparent;
}

.image-modal ::-webkit-scrollbar {
  display: none;
}

.image-modal button.p-button .pi {
  color: #fff;
  font-size: 3em;
}

.shine,
.chrome {
  font-size: 2.5em;
  margin: 0 auto;
  margin-bottom: 1em;
  font-weight: bold;
}

.shine {
  background: #fff -webkit-gradient(linear, left top, right top, from(#fff), to(#fff), color-stop(0.5, #525252)) 0 0 no-repeat;
  -webkit-background-size: 150px;
  color: rgba(255, 255, 255, 0.4);
  -webkit-background-clip: text;
  -webkit-animation-name: shine;
  -webkit-animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
}

@keyframes shine {

  0%,
  10% {
    background-position: -1000px;
  }

  20% {
    background-position: top left;
  }

  90% {
    background-position: top right;
  }

  100% {
    background-position: 1000px;
  }
}